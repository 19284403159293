import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectField,
    BooleanField,
    Labeled,
    NumberField,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const CpuProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Modelo">
                <SelectField
                    source="specs.model"
                    choices={choices.cpu.model}
                    record={record}
                />
            </Labeled>
            <Labeled label="Socket">
                <SelectField
                    source="specs.socket"
                    choices={choices.cpu.socket}
                    record={record}
                />
            </Labeled>
            <Labeled label="Tiene cooler">
                <BooleanField
                    source="specs.has_cooler"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Watts consumidos">
                <NumberField
                    source="specs.watts"
                    record={record}
                />
            </Labeled>
            <Labeled label="Gráficos integrados">
                <BooleanField
                    source="specs.igpu"
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default CpuProductSpecsField;
