import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    TextField,
    SelectField,
    NumberField,
    Labeled,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const GpuProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Tamaño (mm)">
                <NumberField
                    source="specs.width"
                    record={record}
                />
            </Labeled>
            <Labeled label="Watts consumidos">
                <NumberField
                    source="specs.watts"
                    record={record}
                />
            </Labeled>
            <Labeled label="Chip">
                <SelectField
                    source="specs.chip"
                    choices={choices.gpu.chips}
                    record={record}
                />
            </Labeled>
            <Labeled label="Modelo">
                <TextField
                    source="specs.model"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Tecnología VRAM">
                <TextField
                    source="specs.vramTech"
                    record={record}
                />
            </Labeled>
            <Labeled label="Capacidad VRAM">
                <TextField
                    source="specs.vramCapacity"
                    record={record}
                />
            </Labeled>
            <Labeled label="HDMI">
                <NumberField
                    source="specs.hdmi"
                    record={record}
                />
            </Labeled>
            <Labeled label="Display ports">
                <NumberField
                    source="specs.displayPorts"
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default GpuProductSpecsField;
