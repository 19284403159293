import * as React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    ShowButton,
    TextField,
    SelectField,
} from 'react-admin';

import UserFilter from './UserFilter';
import choices from './choices';

export default (props) => (
    <List {...props} filters={<UserFilter />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="email" />
            <TextField source="first_name" />
            <TextField source="last_name" />
            <SelectField source="role" choices={choices.roles} />
            <EditButton basePath="/users" />
            <ShowButton basePath="/users" />
        </Datagrid>
    </List>
);
