import { orderStatus, orderCancellationReasons } from './choices';

// eslint-disable-next-line no-confusing-arrow
export const cancellationReasonValidation = (value, status) => status === orderStatus.cancelled
    && !value
    ? 'Este campo es requerido cuando el estado es "cancelado"'
    : undefined;

// eslint-disable-next-line no-confusing-arrow
export const cancellationReasonDetailValidation = (value, reason) => reason
    === orderCancellationReasons.other && !value
    ? 'Este campo es requerido cuando el estado es "cancelado"'
    : undefined;
