import React from 'react';
import {
    Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart,
} from 'recharts';
import cartHelper from '../helpers/cartHelper';

const getNameOfTheMonthByIndex = (index) => {
    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre',
    ];
    return months[index];
};

const buildSalesDataForLast12Months = (salesPerMonth) => {
    const today = new Date();
    const iterable = Array.from({ length: 12 }, (x, i) => i);

    return iterable.reverse().map((value) => {
        const monthIndex = new Date(today.getFullYear(), today.getMonth() - value).getMonth();
        const month = monthIndex + 1;
        return ({
            month: getNameOfTheMonthByIndex(monthIndex),
            totalSalesPrice: salesPerMonth
                .find((item) => item.month === month)?.totalSalesPrice || 0,
            orders: salesPerMonth
                .find((item) => item.month === month)?.orders || 0,
        });
    });
};

const customLegend = [
    {
        value: 'Ventas ($)', type: 'square', id: 'ID01', color: '#2196f3',
    },
    {
        value: 'Órdenes', type: 'square', id: 'ID02', color: '#000000',
    },
];

const SalesChart = ({ salesPerMonth }) => {
    const salesDataForLast12Months = buildSalesDataForLast12Months(salesPerMonth);

    return (
        <ResponsiveContainer height={400}>
            <BarChart data={salesDataForLast12Months} margin={{ left: 70, top: 10 }}>
                <CartesianGrid stroke="#f2f2f2" />
                <XAxis
                    dataKey="month"
                    tick={{ fontSize: 12 }}
                />
                <YAxis
                    yAxisId="left"
                    orientation="left"
                    tickFormatter={cartHelper.formatPrice}
                />
                <YAxis
                    yAxisId="right"
                    orientation="right"
                />
                <Tooltip formatter={(value, name) => (name === 'Ventas ($)' ? cartHelper.formatPrice(value) : value)} />
                <Legend payload={customLegend} />
                <Bar yAxisId="left" dataKey="totalSalesPrice" fill="#2196f3" barSize={40} name="Ventas ($)" />
                <Bar yAxisId="right" dataKey="orders" barSize={40} name="Órdenes" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default SalesChart;
