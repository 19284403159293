import * as React from 'react';
import {
    Button, useDataProvider, useNotify,
} from 'react-admin';
import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField,
} from '@material-ui/core';
import { VpnKey } from '@material-ui/icons';

export default ({ record }) => {
    const [newPassword, setNewPassword] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePassword = () => {
        if (newPassword !== '') {
            setLoading(true);
            dataProvider.changePassword('users', {
                id: record.id,
                password: newPassword,
            })
                .then(({ data }) => {
                    setLoading(false);
                    handleClose();
                    notify('Password successfully changed!');
                    setNewPassword('');
                })
                .catch((error) => {
                    setLoading(false);
                    handleClose();
                    console.error(error);
                    notify('Error changing password', 'warning');
                });
        } else notify('Please type a new password');
    };

    const dialog = (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Change password</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please type the new password
                </DialogContentText>
                <TextField
                    autoFocus
                    required
                    label="New password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} label="Cancel" />
                <Button disabled={loading} onClick={handleChangePassword} label="Change password" />
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <Button label="Change Password" onClick={handleClickOpen} disabled={open} alignIcon="left">
                <VpnKey />
            </Button>
            {dialog}
        </>
    );
};
