import * as React from 'react';
import {
    BooleanField,
    NumberField,
    Datagrid,
    EditButton,
    List,
    SelectField,
    ShowButton,
    TextField,
} from 'react-admin';
import choices from './choices';
import ProductFilter from './ProductFilter';

export default (props) => (
    <List {...props} filters={<ProductFilter />} bulkActionButtons={false}>
        <Datagrid>
            <SelectField choices={choices.product_types} source="type" />
            <TextField source="name" />
            <TextField source="code" />
            <NumberField source="stock" />
            <NumberField source="salesQuantity" label="Cantidad de ventas" />
            <NumberField source="price.special.USD" label="Precio USD" />
            <NumberField source="price.special.ARS" label="Precio ARS" />
            <BooleanField source="published" />
            <BooleanField source="pricing.hasCustomPricing" label="Has custom pricing" />
            <EditButton basePath="/products" />
            <ShowButton basePath="/products" />
        </Datagrid>
    </List>
);
