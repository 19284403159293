const getPriceSum = (array) => array.reduce((item, { price }) => item + price, 0);

const formaterPesosLocales = 'es-AR';
const formaterPesosStyle = 'currency';
const formaterPesosCurrency = 'ARS';

const formatterPesos = new Intl.NumberFormat(formaterPesosLocales, {
    style: formaterPesosStyle,
    currency: formaterPesosCurrency,
});

const formatPrice = (price) => {
    const formattedPrice = formatterPesos.format(price);
    return formattedPrice;
};

function getTotalPrice(items) {
    const totalPrice = formatPrice(getPriceSum(items));

    return totalPrice;
}

function getProvinces() {
    const provinces = [
        { name: 'Buenos Aires', id: 'Buenos Aires' },
        { name: 'Capital Federal', id: 'Capital Federal' },
        { name: 'Catamarca', id: 'Catamarca' },
        { name: 'Chaco', id: 'Chaco' },
        { name: 'Chubut', id: 'Chubut' },
        { name: 'Córdoba', id: 'Cordoba' },
        { name: 'Corrientes', id: 'Corrientes' },
        { name: 'Entre Ríos', id: 'Entre Rios' },
        { name: 'Formosa', id: 'Formosa' },
        { name: 'Jujuy', id: 'Jujuy' },
        { name: 'La Pampa', id: 'La Pampa' },
        { name: 'La Rioja', id: 'La Rioja' },
        { name: 'Mendoza', id: 'Mendoza' },
        { name: 'Misiones', id: 'Misiones' },
        { name: 'Neuquén', id: 'Neuquen' },
        { name: 'Río Negro', id: 'Rio Negro' },
        { name: 'Salta', id: 'Salta' },
        { name: 'San Juan', id: 'San Juan' },
        { name: 'San Luis', id: 'San Luis' },
        { name: 'Santa Cruz', id: 'Santa Cruz' },
        { name: 'Santa Fe', id: 'Santa Fe' },
        { name: 'Santiago del Estero', id: 'Santiago del Estero' },
        { name: 'Tierra del Fuego', id: 'Tierra del Fuego' },
        { name: 'Tucumán', id: 'Tucuman' },
    ];
    return provinces;
}

const cartHelper = {
    getTotalPrice,
    formatPrice,
    getProvinces,
    formaterPesosLocales,
    formaterPesosStyle,
    formaterPesosCurrency,
};

export default cartHelper;
