import * as React from 'react';
import { Button } from 'react-admin';
import { LocalShipping } from '@material-ui/icons';

export default ({ record }) => {
    if (record?.shippingData?.labelsUrl) {
        return (
            <Button
                href={record.shippingData.labelsUrl}
                target="_blank"
                label="Download Shipping Labels"
            >
                <LocalShipping />
            </Button>
        );
    }
    return null;
};
