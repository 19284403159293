import React from 'react';
import { useNotify } from 'react-admin';
import { Box, IconButton } from '@material-ui/core';
import FileCopy from '@material-ui/icons/FileCopy';

const CopyProductInformation = ({ code, name, serialNumber }) => {
    const notify = useNotify();

    const handleCopy = () => {
        let copyValue = `P/N: ${code} ${name}`;
        if (serialNumber) copyValue += ` S/N: ${serialNumber}`;
        navigator.clipboard.writeText(copyValue).then(() => {
            notify('Datos copiados!', 'success');
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton onClick={handleCopy} size="small">
                <FileCopy size="small" />
            </IconButton>
        </Box>
    );
};

export default CopyProductInformation;
