const fileToBase64 = ({ url, rawFile, ...rest }) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve({ base64: reader.result, ...rest });
    reader.onerror = reject;

    reader.readAsDataURL(rawFile);
});
const resourcesWithImages = [
    {
        resource: 'games',
        field: 'image',
        multiple: false,
    },
    {
        resource: 'builds',
        field: 'images',
        multiple: true,
    },
    {
        resource: 'products',
        field: 'images',
        multiple: true,
    },
    {
        resource: 'slides',
        field: 'image',
        multiple: false,
    },
    {
        resource: 'brandImages',
        field: 'image',
        multiple: false,
    },
    {
        resource: 'users',
        field: 'image',
        multiple: false,
    },
];
const isUploadingImage = (p) => p.rawFile instanceof File;
const isUnchangedImage = (p) => !(p.rawFile instanceof File);
const injectImagesInPayload = (resource, params) => {
    const resourceConfiguration = resourcesWithImages.find((i) => i.resource === resource);
    if (!resourceConfiguration) return Promise.resolve(params);
    const { field, multiple } = resourceConfiguration;
    if (!field) return Promise.resolve(params);
    if (multiple) {
        const originalImages = params.data[field] ?? [];
        const formerImages = originalImages.filter(isUnchangedImage);
        const imagesToUpload = originalImages.filter(isUploadingImage);

        return Promise
            .all(imagesToUpload.map(fileToBase64))
            .then((imagesInBase64) => ({
                ...params,
                data: {
                    ...params.data,
                    images: imagesInBase64.concat(formerImages),
                },
            }));
    }
    if (!params.data[field] || isUnchangedImage(params.data[field])) return Promise.resolve(params);
    return fileToBase64(params.data[field])
        .then((imageInBase64) => ({
            ...params,
            data: {
                ...params.data,
                image: imageInBase64,
            },
        }));
};

export default injectImagesInPayload;
