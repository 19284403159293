import React, { Fragment } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import {
    SelectField,
    BooleanField,
    Labeled,
    NumberField,
    TextField,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';
import { fanData } from '../constants';

const CaseProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Fuente">
                <BooleanField
                    source="specs.powerSupplyIncluded"
                    record={record}
                />
            </Labeled>
            <Labeled label="Watts fuente">
                <NumberField
                    source="specs.includedPowerSupplySupportedWatts"
                    record={record}
                />
            </Labeled>
            <Labeled label="Periféricos">
                <BooleanField
                    source="specs.peripheralsIncluded"
                    record={record}
                />
            </Labeled>
            <Labeled label="Mother Form factor">
                <SelectField
                    source="specs.mother_form_factor"
                    choices={choices.mother.form_factor}
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Tamaño de fuente">
                <SelectField
                    source="specs.size"
                    choices={choices.power.format}
                    record={record}
                />
            </Labeled>
            <Labeled label="Ubicación fuente">
                <SelectField
                    source="specs.powerSupplySlotLocation"
                    choices={choices.case.powerSupplySlotLocation}
                    record={record}
                />
            </Labeled>
            <Labeled label="Iluminación">
                <SelectField
                    source="specs.lighting"
                    choices={choices.case.lighting}
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Tamaño Gabinete">
                <SelectField
                    source="specs.form_factor"
                    choices={choices.case.formFactor}
                    record={record}
                />
            </Labeled>
            <Labeled label="Width (mm)">
                <NumberField
                    source="specs.width"
                    record={record}
                />
            </Labeled>
            <Labeled label="Watts consumed">
                <NumberField
                    source="specs.watts"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Radiator Support">
                <TextField
                    source="specs.radiator_support"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Watercooler 120">
                <BooleanField
                    source="specs.compatibilityWithWatercooler120"
                    record={record}
                />
            </Labeled>
            <Labeled label="Watercooler 140">
                <BooleanField
                    source="specs.compatibilityWithWatercooler140"
                    record={record}
                />
            </Labeled>
            <Labeled label="Watercooler 240">
                <BooleanField
                    source="specs.compatibilityWithWatercooler240"
                    record={record}
                />
            </Labeled>
            <Labeled label="Watercooler 280">
                <BooleanField
                    source="specs.compatibilityWithWatercooler280"
                    record={record}
                />
            </Labeled>
            <Labeled label="Watercooler 360">
                <BooleanField
                    source="specs.compatibilityWithWatercooler360"
                    record={record}
                />
            </Labeled>
            <Labeled label="Watercooler 420">
                <BooleanField
                    source="specs.compatibilityWithWatercooler420"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" width="80%">
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={3} />
                <Grid item xs={3}>
                    <Typography variant="subtitle1">120mm</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1">140mm</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="subtitle1">Híbrido 120/140</Typography>
                </Grid>

                {fanData.map((row) => (
                    <Fragment key={row.label}>
                        <Grid item xs={3}>
                            <Typography>{row.label}</Typography>
                        </Grid>
                        {row.sources.map((source) => (
                            <Grid item xs={3} key={source} style={source === null ? { backgroundColor: '#ccc' } : {}}>
                                {source ? (
                                    <NumberField source={source} label="" record={record} />
                                ) : null}
                            </Grid>
                        ))}
                    </Fragment>
                ))}
            </Grid>
        </Box>
    </Box>
);

export default CaseProductSpecsField;
