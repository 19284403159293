// LoginPage.js
import { Box } from '@material-ui/core';
import React from 'react';
import { Login, LoginForm } from 'react-admin';
import GoogleLoginButton from './GoogleLoginButton';

const handleGoogleLogin = (e) => {
    e.preventDefault();
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
};

const CustomLoginForm = (props) => (
    <div>
        <LoginForm {...props} />
        <Box pl={1} pr={1}>
            <GoogleLoginButton
                onClick={handleGoogleLogin}
            />
        </Box>
    </div>
);

const CustomLoginPage = (props) => (
    <>
        <Login {...props}>
            <CustomLoginForm {...props} />
        </Login>
    </>
);

export default CustomLoginPage;
