import * as React from 'react';
import {
    ArrayField, ImageField,
    Show, SingleFieldList, Tab, TabbedShowLayout, TextField,
} from 'react-admin';
import GameTitle from './GameTitle';

export default (props) => (
    <Show title={<GameTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Main">
                <TextField source="name" />
                <ImageField label="Image" source="image.url" title="image.title" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);
