import * as React from 'react';
import {
    Datagrid,
    EditButton,
    List,
    NumberField,
    ShowButton,
    TextField,
} from 'react-admin';

export default (props) => (
    <List {...props} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="code" />
            <NumberField source="discount_percentage" />
            <TextField source="date_from" />
            <EditButton basePath="/coupons" />
            <ShowButton basePath="/coupons" />
        </Datagrid>
    </List>
);
