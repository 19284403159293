import React from 'react';
import { TextInput, NumberInput } from 'react-admin';
import { required } from 'ra-core';
import {
    Table, TableRow, TableBody, TableCell,
    InputAdornment,
} from '@material-ui/core';
import cartHelper from '../../../helpers/cartHelper';
import { SHIPPING_TYPES_LABELS } from '../constants';
import { getOrderShippingWithoutIva } from '../../../helpers/orderHelper';
import TableHeadForColumns from './TableHeadForColumns';

const ShippingDataTableHead = () => {
    const columns = ['Tipo de envío', 'Descripción', 'Bultos', 'Costo', 'IVA (%)', 'Envío'];
    return <TableHeadForColumns columns={columns} />;
};

const OrderShippingDataTable = ({ order, editable }) => (
    <Table size="small">
        <ShippingDataTableHead />
        <TableBody>
            <TableRow>
                <TableCell style={{ borderBottom: 'none' }}>
                    {SHIPPING_TYPES_LABELS[
                        order.shippingData.shippingType
                    ]}
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    {editable ? (
                        <TextInput
                            record={order}
                            source="shippingData.description"
                            label="descripción"
                        />
                    ) : (order.shippingData.description)}
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    {order.vcRequest?.NumeroBultos ?? ' - '}
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    {cartHelper
                        .formatPrice(getOrderShippingWithoutIva(order))}
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    {editable ? (
                        <NumberInput
                            record={order}
                            source="shippingData.iva"
                            label="iva"
                            validate={[required('El campo IVA es requerido')]}
                            min={0}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">%</InputAdornment>
                                ),
                            }}
                        />
                    ) : (
                        `${order.shippingData.iva} %`
                    )}
                </TableCell>
                <TableCell style={{ borderBottom: 'none' }}>
                    {cartHelper
                        .formatPrice(order.paymentData.shippingPrice)}
                </TableCell>
            </TableRow>
        </TableBody>
    </Table>
);

export default OrderShippingDataTable;
