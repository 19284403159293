import * as React from 'react';
import { Box } from '@material-ui/core';
import { SelectInput } from 'react-admin';
import choices from '../choices';
import classes from './styles';

const ChairProductSpecsInput = () => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <SelectInput
                choices={choices.chair.material}
                label="Material"
                source="specs.material"
                style={classes.textInputStyles}
            />
            <SelectInput
                choices={choices.chair.maximumSupportedWeight}
                label="Peso Máximo Soportado"
                source="specs.maximumSupportedWeight"
                style={classes.textInputStyles}
            />
        </Box>
    </Box>
);

export default ChairProductSpecsInput;
