import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectField,
    BooleanField,
    Labeled,
    NumberField,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const MouseProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="DPI Máximo">
                <NumberField
                    source="specs.maxDPI"
                    record={record}
                />
            </Labeled>
            <Labeled label="DPI Ajustable">
                <BooleanField
                    source="specs.adjustableDPI"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Conectividad">
                <SelectField
                    source="specs.connectivity"
                    choices={choices.mouse.connectivity}
                    record={record}
                />
            </Labeled>
            <Labeled label="Iluminación">
                <SelectField
                    source="specs.lighting"
                    choices={choices.mouse.lighting}
                    record={record}
                />
            </Labeled>
            <Labeled label="Botones Programables">
                <BooleanField
                    source="specs.programmableButtons"
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default MouseProductSpecsField;
