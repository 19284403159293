import * as React from 'react';
import {
    Create, FormTab, TabbedForm, TextInput, ImageInput, ImageField,
} from 'react-admin';
import { required } from 'ra-core';

// TODO Falta hacer que funcione bien con 1 imagen oslamente a nivel admin y core
export default (props) => (
    <Create title="Create a Game" {...props}>
        <TabbedForm>
            <FormTab label="Main">
                <TextInput source="name" validate={[required()]} />
                <ImageInput source="image">
                    <ImageField source="url" title="title" />
                </ImageInput>
            </FormTab>
        </TabbedForm>
    </Create>
);
