import * as React from 'react';
import {
    Title,
    useQueryWithStore,
    useRefresh,
    useUpdate,
} from 'react-admin';
import {
    Box,
    Card,
    CardContent,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    Typography,
} from '@material-ui/core';

import DashboardCard from './DashboardCard';
import TestViaCargo from './TestViaCargo';
import cartHelper from '../helpers/cartHelper';
import SalesChart from './SalesChart';

export default ({ permissions }) => {
    const refresh = useRefresh();
    const { data, loading: loadingGet } = useQueryWithStore({
        type: 'dashboard',
    });
    const [update, { loading: loadingPut }] = useUpdate();
    const handleChangeDisableSelling = (event) => {
        update({
            resource: 'configs',
            payload: {
                id: 'SELLING_DISABLED',
                data: { value: event.target.checked },
            },
        }, {
            onSuccess: () => { refresh(); },
        });
    };

    return (
        <Box pt={3}>
            <Title title="GamerFactory Dashboard" />
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title="Ventas del día"
                        mainContent={
                            cartHelper.formatPrice(data?.dailyResults?.totalSalesPrice ?? 0)
                        }
                        additionalContent={`${data?.dailyResults?.totalOrders ?? 0} órdenes`}
                        footer={`Desde ${(new Date(data?.dayDateFrom)).toLocaleString()}`}
                        loading={loadingGet}
                        backgroundColor="#D1FFC1"
                        mainColor="#007505"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title="Ventas de la semana"
                        mainContent={
                            cartHelper.formatPrice(data?.weeklyResults?.totalSalesPrice ?? 0)
                        }
                        additionalContent={`${data?.weeklyResults?.totalOrders ?? 0} órdenes`}
                        footer={`Desde ${(new Date(data?.weekDateFrom)).toLocaleString()}`}
                        loading={loadingGet}
                        backgroundColor="#D1FFC1"
                        mainColor="#007505"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title="Ventas del mes"
                        mainContent={
                            cartHelper.formatPrice(data?.monthlyResults?.totalSalesPrice ?? 0)
                        }
                        additionalContent={`${data?.monthlyResults?.totalOrders ?? 0} órdenes`}
                        footer={`Desde ${(new Date(data?.monthDateFrom)).toLocaleString()}`}
                        loading={loadingGet}
                        backgroundColor="#D1FFC1"
                        mainColor="#007505"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title="Pendiente de pago"
                        mainContent={
                            `${data?.pendingPayment?.totalOrders ?? 0} órdenes`
                        }
                        additionalContent={
                            cartHelper.formatPrice(data?.pendingPayment?.totalSalesPrice ?? 0)
                        }
                        loading={loadingGet}
                        backgroundColor="#FFF6A1"
                        mainColor="#6C6300"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title="Pendiente de envío"
                        mainContent={
                            `${data?.pendingShipping?.totalOrders ?? 0} órdenes`
                        }
                        additionalContent={
                            cartHelper.formatPrice(data?.pendingShipping?.totalSalesPrice ?? 0)
                        }
                        loading={loadingGet}
                        backgroundColor="#FFF6A1"
                        mainColor="#6C6300"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title="En viaje"
                        mainContent={
                            `${data?.shippingInProgress?.totalOrders ?? 0} órdenes`
                        }
                        additionalContent={
                            cartHelper.formatPrice(data?.shippingInProgress?.totalSalesPrice ?? 0)
                        }
                        loading={loadingGet}
                        backgroundColor="#FFF6A1"
                        mainColor="#6C6300"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title="Ventas incompletas"
                        mainContent={
                            cartHelper.formatPrice(data?.incompletedSales?.totalSalesPrice ?? 0)
                        }
                        additionalContent={`${data?.incompletedSales?.totalOrders ?? 0} órdenes`}
                        loading={loadingGet}
                        backgroundColor="#FFD5D5"
                        mainColor="#770000"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title="Ventas del mes anterior"
                        mainContent={
                            cartHelper.formatPrice(data?.lastMonthSales?.totalSalesPrice ?? 0)
                        }
                        additionalContent={`${data?.lastMonthSales?.totalOrders ?? 0} órdenes`}
                        loading={loadingGet}
                        footer={
                            `Desde ${(new Date(data?.firstDayOfLastMonth)).toLocaleDateString()} hasta ${(new Date(data?.lastDayOfLastMonth)).toLocaleDateString()}`
                        }
                        backgroundColor="#D1FFC1"
                        mainColor="#007505"
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title="Usuarios"
                        mainContent={data?.totalUsers ?? 0}
                        additionalContent={`${data?.newUsers ?? 0} nuevos hoy`}
                        loading={loadingGet}
                        backgroundColor="#C5DCFF"
                        mainColor="#38404D"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <DashboardCard
                        title="Cotización dólar"
                        mainContent={cartHelper.formatPrice(data?.exchangeRate?.exchangeRate ?? 0)}
                        additionalContent={
                            (new Date(data?.exchangeRate?.datetime)).toLocaleString()
                        }
                        loading={loadingGet}
                        backgroundColor="#B4F5CE"
                        mainColor="#304C3B"
                    />
                </Grid>
                <Grid item md={12}>
                    <Box my={3}>
                        <Typography variant="h4" style={{ marginBottom: 20 }}>Ventas anuales (agrupadas por mes)</Typography>
                        <SalesChart salesPerMonth={data?.salesPerMonth ?? []} />
                    </Box>
                </Grid>
                <Grid item md={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6">Configuraciones</Typography>
                            <Box mt={3}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={(
                                            <Switch
                                                color="primary"
                                                checked={data?.config?.value ?? false}
                                                onChange={handleChangeDisableSelling}
                                                disabled={loadingGet || loadingPut || permissions !== 'admin'}
                                            />
                                        )}
                                        label="Deshabilitar ventas"
                                    />
                                </FormGroup>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item md={12}>
                    <Card>
                        <CardContent>
                            {data && (
                                <>
                                    <Typography variant="h6">Test Destinos/Agencias ViaCargo</Typography>
                                    <Box mt={3}>
                                        <TestViaCargo permissions={permissions} />
                                    </Box>
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};
