import React from 'react';
import Receipt from '@material-ui/icons/Receipt';
import { Tooltip } from '@material-ui/core';

const ProductBillIdIcon = ({ productBillId }) => (
    <Tooltip title={productBillId || 'Sin número de factura'}>
        <Receipt fontSize="small" style={{ color: productBillId ? '#2e7d32' : '#d32f2f' }} />
    </Tooltip>
);

export default ProductBillIdIcon;
