// eslint-disable-next-line import/prefer-default-export
export const fanData = [
    {
        label: 'Frontal PRE',
        sources: ['specs.frontPreinstalledFans120', 'specs.frontPreinstalledFans140', null],
    },
    {
        label: 'Frontal SLOTS',
        sources: ['specs.frontFanSlots120', 'specs.frontFanSlots140', 'specs.frontFanSlotsHybrid'],
    },
    {
        label: 'Superior PRE',
        sources: ['specs.topPreinstalledFans120', 'specs.topPreinstalledFans140', null],
    },
    {
        label: 'Superior SLOTS',
        sources: ['specs.topFanSlots120', 'specs.topFanSlots140', 'specs.topFanSlotsHybrid'],
    },
    {
        label: 'Trasera PRE',
        sources: ['specs.rearPreinstalledFans120', 'specs.rearPreinstalledFans140', null],
    },
    {
        label: 'Trasera SLOTS',
        sources: ['specs.rearFanSlots120', 'specs.rearFanSlots140', 'specs.rearFanSlotsHybrid'],
    },
    {
        label: 'Inferior PRE',
        sources: ['specs.bottomPreinstalledFans120', 'specs.bottomPreinstalledFans140', null],
    },
    {
        label: 'Inferior SLOTS',
        sources: ['specs.bottomFanSlots120', 'specs.bottomFanSlots140', 'specs.bottomFanSlotsHybrid'],
    },
    {
        label: 'Lateral PRE',
        sources: ['specs.sidePreinstalledFans120', 'specs.sidePreinstalledFans140', null],
    },
    {
        label: 'Lateral SLOTS',
        sources: ['specs.sideFanSlots120', 'specs.sideFanSlots140', 'specs.sideFanSlotsHybrid'],
    },
];
