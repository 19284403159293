import * as React from 'react';
import { Filter, SelectInput, TextInput } from 'react-admin';
import choices from './choices';

export default (props) => (
    <Filter {...props}>
        <TextInput label="Search by email" source="email" alwaysOn />
        <TextInput label="Search by first name" source="first_name" />
        <TextInput label="Search by last name" source="last_name" />
        <SelectInput source="role" choices={choices.roles} />
    </Filter>
);
