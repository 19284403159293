const convertToInteger = (num) => Math.trunc(num);
const round2decimals = (v) => (v ? Math.round((v + Number.EPSILON) * 100) / 100 : v);

const truncateDecimals = (v) => (v ? Math.trunc(v) : v);

const roundToUp = (v) => (v ? Math.ceil(v) : v);

module.exports = {
    convertToInteger,
    round2decimals,
    truncateDecimals,
    roundToUp,
};
