export const orderStatus = {
    new: 'new',
    payed: 'payed',
    shippingRequested: 'shippingRequested',
    delivering: 'delivering',
    completed: 'completed',
    cancelled: 'cancelled',
    rma: 'rma',
};

export const orderCancellationReasons = {
    out_of_stock: 'out_of_stock',
    invalid_direction: 'invalid_direction',
    request_cancellation: 'request_cancellation',
    no_client_response: 'no_client_response',
    duplicated: 'duplicated',
    suspected_fraud: 'suspected_fraud',
    error_in_price: 'error_in_price',
    logistic_problems: 'logistic_problems',
    product_unavailable: 'product_unavailable',
    system_error: 'system_error',
    no_payment: 'no_payment',
    other: 'other',
};

const status = [
    { name: 'Nuevo Pedido', id: orderStatus.new },
    { name: 'Pago Aprobado', id: orderStatus.payed },
    { name: 'Envío Solicitado', id: orderStatus.shippingRequested },
    { name: 'En camino', id: orderStatus.delivering },
    { name: 'Completada', id: orderStatus.completed },
    { name: 'Cancelada', id: orderStatus.cancelled },
    { name: 'RMA', id: orderStatus.rma },
];

const paymentMethods = [
    { id: 'card', name: 'Tarjeta de crédito (más de una cuota)' },
    { id: 'bank_transfer', name: 'Transferencia bancaria' },
    { id: 'debit_card_or_one_installment', name: 'Tarjeta de débito o crédito en 1 cuota' },
];

const cancellationReason = [
    { name: 'Producto fuera de stock', id: orderCancellationReasons.out_of_stock },
    { name: 'Dirección de envío incorrecta', id: orderCancellationReasons.invalid_direction },
    { name: 'Cliente solicitó la cancelación', id: orderCancellationReasons.request_cancellation },
    { name: 'Falta de respuesta del cliente para confirmar detalles del pedido', id: orderCancellationReasons.no_client_response },
    { name: 'Pedido duplicado', id: orderCancellationReasons.duplicated },
    { name: 'Pedido sospechoso de fraude', id: orderCancellationReasons.suspected_fraud },
    { name: 'Error en el precio del producto', id: orderCancellationReasons.error_in_price },
    { name: 'Problemas logísticos o de envío', id: orderCancellationReasons.logistic_problems },
    { name: 'Producto no disponible temporalmente', id: orderCancellationReasons.product_unavailable },
    { name: 'Error del sistema', id: orderCancellationReasons.system_error },
    { name: 'Pago no realizado dentro de las 24 horas', id: orderCancellationReasons.no_payment },
    { name: 'Otro', id: 'other' },
];

const invoicesTypes = [
    { name: 'Factura A', id: 'invoiceA' },
    { name: 'Consumidor final', id: 'finalConsumer' },
];

const ivaConditions = [
    { name: 'IVA Responsable Inscripto', id: 'ivaResponsableInscripto' },
    { name: 'Monotributista Trabajador Independiente Promovido', id: 'monotributistaIndependientePromovido' },
    { name: 'Monotributista Social', id: 'monotributistaSocial' },
    { name: 'Responsable Monotributo', id: 'responsableMonotributo' },
];

export default {
    status,
    paymentMethods,
    cancellationReason,
    invoicesTypes,
    ivaConditions,
};
