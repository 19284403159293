import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    NumberInput,
    SelectInput,
    BooleanInput,
} from 'react-admin';
import { required } from 'ra-core';

import choices from '../choices';
import classes from './styles';

const handleMotherRamStickTypeChoices = (values) => {
    if (values.specs?.cpu_socket && values.specs?.cpu_socket === 'AM5') {
        return choices.ram.stick_type.filter((ramStick) => ramStick.name === 'DDR5');
    }
    return choices.ram.stick_type;
};

const MotherProductSpecsInput = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <SelectInput
                label="Socket"
                addLabel
                source="specs.cpu_socket"
                choices={choices.cpu.socket}
                validate={[required()]}
            />
            <SelectInput
                label="Tipo de RAM"
                addLabel
                source="specs.ram_stick_type"
                choices={handleMotherRamStickTypeChoices(record)}
                validate={[required()]}
            />
            <SelectInput
                label="Form factor"
                source="specs.form_factor"
                choices={choices.mother.form_factor}
                validate={[required()]}
            />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <NumberInput
                label="Slots RAM"
                source="specs.ram_slots"
                validate={[required()]}
            />
            <NumberInput
                label="Slots PCIe 16x"
                source="specs.pci_express_16x_slots"
                validate={[required()]}
            />
            <NumberInput
                label="Puertos SATA"
                source="specs.sata3_slots"
                validate={[required()]}
            />
            <NumberInput
                label="Slots M.2"
                source="specs.m2_slots"
                validate={[required()]}
            />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <BooleanInput
                label="Video Output"
                source="specs.video_output"
                validate={[required()]}
                defaultValue={false}
            />
            <NumberInput
                label="Watts consumed"
                source="specs.watts"
                validate={[required()]}
            />
            <BooleanInput
                label="Wifi integrado"
                source="specs.integratedWifi"
                style={{ width: '200px' }}
                defaultValue={false}
            />
        </Box>
    </Box>
);

export default MotherProductSpecsInput;
