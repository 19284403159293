import React from 'react';
import Box from '@material-ui/core/Box';
import {
    Button, TextInput, useMutation, useNotify,
} from 'react-admin';

import { Stars } from '@material-ui/icons';
import { useFormState, useForm } from 'react-final-form';

const ProductSEO = ({ disabled = false }) => {
    const notify = useNotify();
    const form = useForm();
    const { values: { id } } = useFormState();

    const [generateAiDescription, { loading }] = useMutation({
        type: 'generateAiDescription',
        payload: { id },
    }, {
        onSuccess: ({ data }) => { form.change('seo.description', data.description); },
        onFailure: () => { notify('Error al generar meta description', 'warning'); },
    });

    return (
        <Box>
            <Button type="button" label="Generar" style={{ margin: '17px 10px 0' }} disabled={disabled || loading} onClick={generateAiDescription}>
                <Stars />
            </Button>
            <TextInput disabled={disabled} multiline source="seo.description" label="Meta description" style={{ width: '600px' }} />
        </Box>
    );
};

export default ProductSEO;
