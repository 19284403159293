import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectInput,
    NumberInput,
    required,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const CaseFanProductSpecsInput = () => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <NumberInput
                label="Watts consumidos"
                source="specs.watts"
                validate={[required()]}
                style={classes.textInputStyles}
            />
            <SelectInput
                label="Tamaño"
                source="specs.width"
                choices={choices.case_fan.width}
                validate={[required()]}
                style={classes.textInputStyles}
            />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <SelectInput
                label="Conexión"
                source="specs.connection"
                choices={choices.case_fan.connection}
                style={classes.textInputStyles}
            />
            <SelectInput
                label="Iluminación"
                source="specs.lighting"
                choices={choices.case_fan.lighting}
                style={classes.textInputStyles}
            />
        </Box>
    </Box>
);

export default CaseFanProductSpecsInput;
