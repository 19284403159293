import * as React from 'react';
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    NumberInput,
    DateTimeInput,
    NumberField,
} from 'react-admin';
import { required } from 'ra-core';
import CouponTitle from './CouponTitle';

export default (props) => (
    <Edit title={<CouponTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Main">
                <TextInput source="code" label="Código" validate={[required()]} />
                <NumberInput source="discount_percentage" label="Porcentaje de descuento" min={0} max={100} validate={[required()]} />
                <DateTimeInput source="date_from" label="Desde" showTime validate={[required()]} />
                <DateTimeInput source="date_to" label="Hasta" showTime />
                <NumberInput source="max_uses" label="Límite de usos" min={0} />
                <NumberField source="current_uses" label="Usos realizados" min={0} />
            </FormTab>
        </TabbedForm>
    </Edit>
);
