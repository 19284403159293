import * as React from 'react';
import {
    BooleanInput, Filter, SelectInput, TextInput, AutocompleteInput,
} from 'react-admin';
import choices from './choices';

export default (props) => (
    <Filter {...props}>
        <TextInput label="Search by name" source="name" alwaysOn />
        <TextInput label="Search by code" source="code" alwaysOn />
        <BooleanInput label="Search by published" source="published" />
        <BooleanInput label="Has Custom Pricing" source="pricing.hasCustomPricing" />
        <SelectInput source="type" choices={choices.product_types} />
        <AutocompleteInput label="Brand" source="brand" choices={choices.brands} />
    </Filter>
);
