import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectField,
    BooleanField,
    Labeled,
} from 'react-admin';
import choices, { mechanicalKeyboardType } from '../choices';

const KeyboardProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
            <Labeled label="Tipo de teclado">
                <SelectField
                    source="specs.type"
                    choices={choices.keyboardTypes}
                    record={record}
                />
            </Labeled>
            {
                record.specs?.type === mechanicalKeyboardType && (
                    <Labeled label="Switch">
                        <SelectField
                            source="specs.switch"
                            choices={choices.keyboardSwitches}
                            record={record}
                        />
                    </Labeled>
                )
            }
            <Labeled label="Retroiluminación">
                <SelectField
                    source="specs.backlight"
                    choices={choices.keyboardBacklights}
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row">
            <Labeled label="Conectividad" style={{ marginRight: '16px' }}>
                <SelectField
                    source="specs.connectivity"
                    choices={choices.keyboardConnectivity}
                    record={record}
                />
            </Labeled>
            <Labeled label="Tamaño" style={{ marginRight: '16px' }}>
                <SelectField
                    source="specs.size"
                    choices={choices.keyboardSize}
                    record={record}
                />
            </Labeled>
            <Labeled label="Teclas Programables">
                <BooleanField
                    source="specs.programmableKeys"
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default KeyboardProductSpecsField;
