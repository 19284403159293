import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    NumberField,
    SelectField,
    BooleanField,
    Labeled,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const MotherProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Socket">
                <SelectField
                    source="specs.cpu_socket"
                    choices={choices.cpu.socket}
                    record={record}
                />
            </Labeled>
            <Labeled label="Tipo de RAM">
                <SelectField
                    source="specs.ram_stick_type"
                    choices={choices.ram.stick_type}
                    record={record}
                />
            </Labeled>
            <Labeled label="Form factor">
                <SelectField
                    source="specs.form_factor"
                    choices={choices.mother.form_factor}
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Slots RAM">
                <NumberField
                    source="specs.ram_slots"
                    record={record}
                />
            </Labeled>
            <Labeled label="Slots PCIe 16x">
                <NumberField
                    source="specs.pci_express_16x_slots"
                    record={record}
                />
            </Labeled>
            <Labeled label="Puertos SATA">
                <NumberField
                    source="specs.sata3_slots"
                    record={record}
                />
            </Labeled>
            <Labeled label="Slots M.2">
                <NumberField
                    source="specs.m2_slots"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Video output">
                <BooleanField
                    source="specs.video_output"
                    record={record}
                />
            </Labeled>
            <Labeled label="Watts consumed">
                <NumberField
                    source="specs.watts"
                    record={record}
                />
            </Labeled>
            <Labeled label="Wifi integrado">
                <BooleanField
                    source="specs.integratedWifi"
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default MotherProductSpecsField;
