import * as React from 'react';
import { Field, useFormState } from 'react-final-form';
import { BooleanInput, useQueryWithStore } from 'react-admin';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default () => {
    const { values } = useFormState();

    const useStyles = makeStyles({
        hidden: { visibility: 'hidden', width: '0px', height: '0px' },
    });
    const classes = useStyles();
    const isGameCompatible = (gameId) => values?.specs?.games_compatibility
        ?.some(({ id }) => id === gameId);

    const { data } = useQueryWithStore({
        type: 'getList',
        resource: 'games',
        payload: { pagination: { page: 1, perPage: 999 } },
    });

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell>Game</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data && data.map((game, index) => {
                    const source = (field) => `specs.games_compatibility[${index}].${field}`;

                    return (
                        <TableRow key={game.id}>
                            <TableCell>
                                {game.name}
                                <Field className={classes.hidden} name={source('game')} component="input" type="text" defaultValue={game.id} />
                            </TableCell>
                            <TableCell>
                                <BooleanInput
                                    defaultChecked={isGameCompatible(game.id)}
                                    label=""
                                    source={source('isCompatible')}
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};
