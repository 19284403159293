import React from 'react';
import GoogleButton from 'react-google-button';

function GoogleLoginButton(props) {
    const { onClick } = props;
    return (
        <GoogleButton
            type="light"
            label="Continuar con Google"
            onClick={onClick}
            style={
                {
                    height: '33px',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    margin: '16px 0',
                    border: 0,
                    fontFamily: 'Roboto',
                    width: '237px',
                    padding: '0px 47px 16px 0px',
                }
            }
        />
    );
}

export default GoogleLoginButton;
