import * as React from 'react';
import { useState } from 'react';
import { Box } from '@material-ui/core';
import {
    SelectInput,
    BooleanInput,
    NumberInput,
    useInput,
} from 'react-admin';
import { required } from 'ra-core';
import {
    StorageConnections, StorageProtocols,
} from '../../../helpers/types';

import choices from '../choices';
import classes from './styles';

const StorageProductSpecsInput = ({ record }) => {
    const protocolInput = useInput({ source: 'specs.protocol' });
    const justSataProtocolOptions = choices.storage.just_sata_protocol;
    const allProtocolOptions = choices.storage.protocols;
    const [protocolOptions, setProtocolOptions] = useState(
        record?.specs?.connection === StorageConnections.SATA
            ? justSataProtocolOptions
            : allProtocolOptions,
    );

    const handleConnectionChange = (event) => {
        const selectedConnection = event.target.value;
        if (selectedConnection === StorageConnections.SATA) {
            setProtocolOptions(justSataProtocolOptions);
            protocolInput.input.onChange(StorageProtocols.SATA3);
        } else {
            setProtocolOptions(allProtocolOptions);
            protocolInput.input.onChange(null);
        }
    };

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" style={classes.boxStyles}>
                <SelectInput
                    label="Tipo"
                    source="specs.type"
                    choices={choices.storage.types}
                    validate={[required()]}
                />
                <NumberInput
                    label="Watts consumed"
                    source="specs.watts"
                    validate={[required()]}
                />
                <SelectInput
                    label="Tecnología"
                    source="specs.technology"
                    choices={choices.storage.technologies}
                    validate={[required()]}
                />
                <NumberInput
                    label="Capacidad (GB)"
                    source="specs.capacity"
                    validate={[required()]}
                />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <SelectInput
                    label="Conexión"
                    source="specs.connection"
                    choices={choices.storage.connections}
                    onChange={handleConnectionChange}
                    validate={[required()]}
                />
                <SelectInput
                    label="Protocolo"
                    source="specs.protocol"
                    choices={protocolOptions}
                    {...protocolInput}
                />
                <BooleanInput
                    label="Compatibilidad Notebook"
                    source="specs.notebookCompatible"
                    defaultValue
                />
            </Box>
        </Box>
    );
};

export default StorageProductSpecsInput;
