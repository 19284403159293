import * as React from 'react';
import {
    Button,
    useNotify,
    useRefresh,
    useDataProvider,
} from 'react-admin';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@material-ui/core';
import GtinIcon from './GtinIcon';

export default ({ record }) => {
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    const [newValue, setNewValue] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleValueChange = () => {
        if (newValue === '') notify('GTIN field empty.', 'warning');
        else {
            setLoading(true);
            dataProvider.setProductGTIN('product', {
                id: record.id,
                gtin: newValue,
            })
                .then(() => {
                    setLoading(false);
                    handleClose();
                    notify('GTIN setted successfully!');
                    setNewValue('');
                    refresh();
                })
                .catch((error) => {
                    setLoading(false);
                    handleClose();
                    console.error(error);
                    notify('Error setting GTIN.', 'warning');
                });
        }
    };

    React.useEffect(() => {
        if (record) setNewValue(record.gtin);
    }, [record]);

    const dialog = (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" {...record}>
            <DialogTitle id="form-dialog-title">Set GTIN</DialogTitle>
            <DialogContent>
                {record
                    && (
                        <>
                            <p>{record.name}</p>
                            <TextField
                                autoFocus
                                required
                                label="GTIN"
                                type="text"
                                value={newValue}
                                onChange={(e) => {
                                    setNewValue(e.target.value);
                                }}
                            />
                        </>
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} label="Cancel" />
                <Button disabled={loading} onClick={handleValueChange} label="Set GTIN" />
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            <Button onClick={handleClickOpen} disabled={open} alignIcon="left" style={{ padding: 0, minWidth: 0 }} size="small">
                <GtinIcon gtin={record.gtin} />
            </Button>
            {dialog}
        </>
    );
};
