import React from 'react';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import { Tooltip } from '@material-ui/core';

const GtinIcon = ({ gtin }) => (
    <Tooltip title={gtin || 'Sin GTIN'}>
        {gtin ? <Check style={{ color: '#2e7d32' }} /> : <Close color="error" />}
    </Tooltip>
);

export default GtinIcon;
