import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    required,
    SelectInput,
    TextInput,
    NumberInput,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';
import { isRecordNew } from './utils';

const GpuProductSpecsInput = ({ record }) => {
    const arrayWithNumbersToFour = [0, 1, 2, 3, 4].map((_, index) => ({ id: index, name: index }));
    const recordIsNew = isRecordNew(record);

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <NumberInput
                    label="Tamaño (mm)"
                    source="specs.width"
                    validate={[required()]}
                    style={classes.textInputStyles}
                />
                <NumberInput
                    label="Watts Consumidos"
                    source="specs.watts"
                    validate={[required()]}
                    style={classes.textInputStyles}
                />
                <SelectInput
                    choices={choices.gpu.chips}
                    label="Chip"
                    source="specs.chip"
                    style={classes.textInputStyles}
                />
                <TextInput
                    label="Modelo"
                    source="specs.model"
                    style={classes.textInputStyles}
                />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <TextInput
                    label="Tecnología VRAM"
                    source="specs.vramTech"
                    style={classes.textInputStyles}
                />
                <TextInput
                    label="Capacidad VRAM"
                    source="specs.vramCapacity"
                    style={classes.textInputStyles}
                />
                <SelectInput
                    choices={arrayWithNumbersToFour}
                    label="HDMI"
                    source="specs.hdmi"
                    style={classes.textInputStyles}
                    defaultValue={recordIsNew ? 0 : undefined}
                />
                <SelectInput
                    choices={arrayWithNumbersToFour}
                    label="Display ports"
                    source="specs.displayPorts"
                    style={classes.textInputStyles}
                    defaultValue={recordIsNew ? 0 : undefined}
                />
            </Box>
        </Box>
    );
};

export default GpuProductSpecsInput;
