import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectField,
    BooleanField,
    Labeled,
    NumberField,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const CaseFanProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Watts consumidos">
                <NumberField
                    source="specs.watts"
                    record={record}
                />
            </Labeled>
            <Labeled label="Tamaño">
                <SelectField
                    source="specs.width"
                    choices={choices.case_fan.width}
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Conexión">
                <SelectField
                    source="specs.connection"
                    choices={choices.case_fan.connection}
                    record={record}
                />
            </Labeled>
            <Labeled label="Iluminación">
                <SelectField
                    source="specs.lighting"
                    choices={choices.case_fan.lighting}
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default CaseFanProductSpecsField;
