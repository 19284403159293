import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectInput,
    SelectArrayInput,
    BooleanInput,
} from 'react-admin';
import choices from '../choices';
import classes from './styles';

const HeadsetProductSpecsInput = () => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <SelectInput
                choices={choices.headset.connectivity}
                label="Conexión"
                source="specs.connectivity"
                style={classes.textInputStyles}
            />
            <SelectArrayInput
                choices={choices.headset.connectionType}
                label="Tipo de Conexión"
                source="specs.connectionType"
                style={classes.textInputStyles}
            />
            <BooleanInput
                label="Con Micrófono"
                source="specs.withMicrophone"
                defaultValue
                style={classes.textInputStyles}
            />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <SelectInput
                choices={choices.headset.lighting}
                label="Iluminación"
                source="specs.lighting"
                style={classes.textInputStyles}
            />
            <SelectInput
                choices={choices.headset.sound}
                label="Sonido"
                source="specs.sound"
                style={classes.textInputStyles}
            />
            <BooleanInput
                label="Cancelación de Ruido"
                source="specs.noiseCancelling"
                defaultValue
                style={classes.textInputStyles}
            />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <SelectInput
                choices={choices.headset.format}
                label="Formato"
                source="specs.format"
                style={classes.textInputStyles}
            />
        </Box>
    </Box>
);

export default HeadsetProductSpecsInput;
