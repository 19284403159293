import React, { useState } from 'react';
import {
    Toolbar,
    Confirm,
    SaveButton,
} from 'react-admin';
import { useFormState } from 'react-final-form';

import { orderStatus } from './choices';

const OrderEditToolbar = (props) => {
    const { pristine } = props;
    const [open, setOpen] = useState(false);
    const { values, errors } = useFormState();

    const newStatus = values.status;

    const handleClick = () => {
        if (newStatus !== orderStatus.cancelled) {
            props.handleSubmitWithRedirect('list');
            return;
        }
        setOpen(true);
    };

    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        handleDialogClose();
        props.handleSubmitWithRedirect('list');
    };

    const thereAreErrors = Object.keys(errors).length > 0;

    return (
        <Toolbar {...props}>
            <SaveButton disabled={thereAreErrors || pristine} type="button" submitOnEnter={false} handleSubmitWithRedirect={handleClick} />
            <Confirm
                isOpen={open}
                title="Estás por cancelar esta orden"
                content="Esta acción es irreversible. ¿Cancelar de todas formas?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Toolbar>
    );
};

export default OrderEditToolbar;
