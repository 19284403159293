import * as React from 'react';
import { useQueryWithStore } from 'react-admin';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { ConfigIds, ConfigsThatAreNotTaxes } from '../../helpers/types';

const useStyles = makeStyles({
    conditionalCustomPricingField: {
        padding: '0px 0px',
        '&:last-child': {
            paddingBottom: '0px',
        },
    },
    titleGlobalTax: {
        padding: '16px 0px',
        color: 'rgba(0, 0, 0, 0.54)',
    },
});

export default () => {
    const classes = useStyles();
    const [dataTaxesConfig, setDataTaxesConfig] = React.useState({});

    const { data: configsData } = useQueryWithStore({
        type: 'getList',
        resource: 'configs',
        payload: { pagination: { page: 1, perPage: 20 } },
    });
    React.useEffect(() => {
        if (configsData) {
            const formatted = configsData
                .filter((config) => !ConfigsThatAreNotTaxes.includes(config.id))
                .reduce((acc, config) => {
                    acc[config.id] = config.value;
                    return acc;
                }, {});
            setDataTaxesConfig(formatted);
        }
    }, [configsData]);

    return (
        <>
            <Divider />
            <Typography variant="subtitle1" className={classes.titleGlobalTax}>
                Valores de los impuestos globales actuales
            </Typography>
            <Box p={1}>
                <TextField value={dataTaxesConfig[ConfigIds.PRICING_IIBB]} label="Ingresos Brutos" size="small" variant="filled" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
                <TextField value={dataTaxesConfig[ConfigIds.PRICING_COM_BANCARIA]} label="Comisión bancaria" size="small" variant="filled" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
                <TextField value={dataTaxesConfig[ConfigIds.PRICING_G_ADM]} label="Gastos Administrativos" size="small" variant="filled" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
                <TextField value={dataTaxesConfig[ConfigIds.PRICING_MARKUP]} label="Markup" size="small" variant="filled" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
                <TextField value={dataTaxesConfig[ConfigIds.PRICING_CMP]} label="Comisión Mercado Pago" size="small" variant="filled" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
            </Box>
            <Box p={1}>
                <TextField value={dataTaxesConfig[ConfigIds.INTEREST_RATE]} label="Interés de la Cuota" size="small" variant="filled" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
                <TextField value={dataTaxesConfig[ConfigIds.PRICING_COM_BANCARIA_PRECIO_LISTA]} label="Comisión bancaria (precio de lista)" style={{ width: '280px' }} size="small" variant="filled" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} />
            </Box>
        </>
    );
};
