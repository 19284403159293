import React from 'react';
import {
    Edit, FormTab, TabbedForm,
} from 'react-admin';
import OrderTitle from './OrderTitle';
import OrderEditToolbar from './OrderEditToolbar';
import MainFieldsTab from './Tabs/MainFieldsTab';
import DetailsTab from './Tabs/DetailsTab';
import ShippingDataTab from './Tabs/ShippingDataTab';

const OrderEdit = (props) => (
    <Edit title={<OrderTitle />} {...props}>
        <TabbedForm toolbar={<OrderEditToolbar />}>
            <FormTab label="Main">
                <MainFieldsTab editable />
            </FormTab>
            <FormTab label="Details">
                <DetailsTab editable />
            </FormTab>
            <FormTab label="Shipping Data">
                <ShippingDataTab editable />
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default OrderEdit;
