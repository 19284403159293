export const SHIPPING_TYPES = {
    homeDelivery: 'homeDelivery',
    pickupAtNearestAgency: 'pickupAtNearestAgency',
    pickupAtWarehouse: 'pickupAtWarehouse',
};

export const SHIPPING_TYPES_LABELS = {
    homeDelivery: 'Envío a domicilio',
    pickupAtNearestAgency: 'Retiro en agencia más cercana',
    pickupAtWarehouse: 'Retiro en deposito',
};
