import * as React from 'react';
import {
    Datagrid, EditButton,
    List, ShowButton,
    TextField, UrlField,
} from 'react-admin';

import BrandImageFilter from './BrandImageFilter';

export default (props) => (
    <List {...props} sort={{ field: 'name', order: 'DESC' }} filters={<BrandImageFilter />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="name" />
            <UrlField source="link" target="_blank" rel="noopener noreferrer" />
            <EditButton basePath="/brandImages" />
            <ShowButton basePath="/brandImages" />
        </Datagrid>
    </List>
);
