import { SHIPPING_TYPES } from '../resources/Orders/constants';

const { round2decimals } = require('./mathHelper');

const getPriceTypeLabel = (order) => (order.priceType === 'list' ? 'LISTA' : 'Especial GF');

const getOrderProductIva = (orderPriceType, singleItemIva = false) => (orderProduct) => {
    const { price, quantity } = orderProduct.item;
    const { iva } = orderProduct.item.productShadow.pricing;
    const orderProductPrice = price[orderPriceType] * (singleItemIva ? 1 : quantity);
    const productPriceWithoutIva = round2decimals(orderProductPrice / (1 + iva / 100));
    return orderProductPrice - productPriceWithoutIva;
};

const getOrderShippingWithoutIva = (order) => order
    .paymentData.shippingPrice / (1 + order.shippingData.iva / 100);

const getOrderProductsIva = (order) => {
    const productsIva = order.products.map(getOrderProductIva(order.priceType));
    const sum = (a, b) => a + b;
    return productsIva.reduce(sum, 0);
};

const getOrderProductsPriceWithoutIva = (order) => {
    const productsIva = getOrderProductsIva(order);
    return order.productsPrice - productsIva;
};

const getOrderShippingTypeColor = (shippingType) => {
    switch (shippingType) {
    case SHIPPING_TYPES.homeDelivery: return 'blue';
    case SHIPPING_TYPES.pickupAtNearestAgency: return 'red';
    default: return 'green';
    }
};

export {
    getPriceTypeLabel,
    getOrderShippingWithoutIva,
    getOrderProductIva,
    getOrderProductsIva,
    getOrderProductsPriceWithoutIva,
    getOrderShippingTypeColor,
};
