import * as React from 'react';
import {
    DateField,
    NumberField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
} from 'react-admin';
import CouponTitle from './CouponTitle';

export default (props) => (
    <Show title={<CouponTitle />} {...props}>
        <TabbedShowLayout>
            <Tab label="Main">
                <TextField source="code" label="Código" />
                <NumberField source="discount_percentage" label="Porcentaje de descuento" />
                <DateField source="date_from" label="Desde" showTime />
                <DateField source="date_to" label="Hasta" showTime />
                <NumberField source="max_uses" label="Límite de usos" />
                <NumberField source="current_uses" label="Usos realizados" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);
