const classes = {
    boxStyles: {
        gap: 16,
    },
    textInputStyles: {
        width: '250px',
    },
};

export default classes;
