import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectField,
    BooleanField,
    Labeled,
    RichTextField,
} from 'react-admin';
import choices from '../choices';
import classes from './styles';

const HeadsetProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Conexion">
                <SelectField
                    source="specs.connectivity"
                    choices={choices.headset.connectivity}
                    record={record}
                />
            </Labeled>
            <Labeled label="Tipo de Conexión">
                <RichTextField
                    source="specs.connectionType"
                    choices={choices.headset.connectionType}
                    record={record}
                />
            </Labeled>
            <Labeled label="Con Micrófono">
                <BooleanField
                    source="specs.withMicrophone"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Iluminación">
                <SelectField
                    source="specs.lighting"
                    choices={choices.headset.lighting}
                    record={record}
                />
            </Labeled>
            <Labeled label="Sonido">
                <SelectField
                    source="specs.sound"
                    choices={choices.headset.sound}
                    record={record}
                />
            </Labeled>
            <Labeled label="Cancelación de Ruido">
                <BooleanField
                    source="specs.noiseCancelling"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Formato">
                <SelectField
                    source="specs.format"
                    choices={choices.headset.format}
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default HeadsetProductSpecsField;
