import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    TextField,
    SelectField,
    NumberField,
    Labeled,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const RAMProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Tipo">
                <SelectField
                    source="specs.type"
                    record={record}
                    choices={choices.ram.type}
                />
            </Labeled>
            <Labeled label="Capacidad">
                <SelectField
                    source="specs.capacity"
                    record={record}
                    choices={choices.ram.capacity}
                />
            </Labeled>
            <Labeled label="Tecnología">
                <SelectField
                    source="specs.stick_type"
                    record={record}
                    choices={choices.ram.stick_type}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Stick Quantity">
                <NumberField
                    source="specs.quantity"
                    record={record}
                />
            </Labeled>
            <Labeled label="Frecuencia">
                <TextField
                    source="specs.clock_frequency"
                    record={record}
                />
            </Labeled>
            <Labeled label="Unidad">
                <SelectField
                    source="specs.clockFrequencyUnit"
                    choices={choices.ram.clock_frequency_unit}
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Latencia">
                <TextField
                    source="specs.cl"
                    record={record}
                />
            </Labeled>
            <Labeled label="Watts consumed">
                <NumberField
                    source="specs.watts"
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default RAMProductSpecsField;
