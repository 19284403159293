import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectInput,
    BooleanInput,
    NumberInput,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const MouseProductSpecsField = () => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <NumberInput
                label="DPI Máximo"
                source="specs.maxDPI"
                style={classes.textInputStyles}
            />
            <BooleanInput
                label="DPI Ajustable"
                source="specs.adjustableDPI"
            />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
            <SelectInput
                choices={choices.mouse.connectivity}
                label="Conectividad"
                source="specs.connectivity"
                style={classes.textInputStyles}
            />
            <SelectInput
                choices={choices.mouse.lighting}
                label="Iluminación"
                source="specs.lighting"
                style={classes.textInputStyles}
            />
            <BooleanInput
                label="Botones Programables"
                source="specs.programmableButtons"
            />
        </Box>
    </Box>
);

export default MouseProductSpecsField;
