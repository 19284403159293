import React from 'react';
import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';

const Providers = () => (
    <ArrayInput source="value" label="Proveedores">
        <SimpleFormIterator>
            <TextInput source="id" label="ID" />
        </SimpleFormIterator>
    </ArrayInput>
);

export default Providers;
