import * as React from 'react';
import {
    Edit, FormTab, ImageField, ImageInput, TabbedForm, TextInput,
} from 'react-admin';
import { required } from 'ra-core';
import BrandImageTitle from './BrandImageTitle';

export default (props) => (
    <Edit title={<BrandImageTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Main">
                <TextInput source="name" validate={[required()]} />
                <TextInput source="link" type="url" validate={[required()]} />
                <ImageInput source="image" validate={[required()]}>
                    <ImageField source="url" title="title" />
                </ImageInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);
