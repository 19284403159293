import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    NumberField,
    SelectField,
    BooleanField,
    Labeled,
} from 'react-admin';

import choices from '../choices';
import classes from './styles';

const PowerProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Watts nominal">
                <NumberField
                    source="specs.watts_supported"
                    record={record}
                />
            </Labeled>
            <Labeled label="Formato">
                <SelectField
                    source="specs.format"
                    choices={choices.power.format}
                    record={record}
                />
            </Labeled>
            <Labeled label="Certificación 80 Plus">
                <SelectField
                    source="specs.certification80Plus"
                    choices={choices.power.certification80Plus}
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Cableado">
                <SelectField
                    source="specs.cabling"
                    choices={choices.power.cabling}
                    record={record}
                />
            </Labeled>
            <Labeled label="Iluminación">
                <BooleanField
                    source="specs.lighting"
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default PowerProductSpecsField;
