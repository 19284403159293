import * as React from 'react';
import {
    BooleanField,
    ArrayField,
    ImageField,
    NumberField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
    SingleFieldList, SelectField,
    Labeled,
    SimpleShowLayout,
} from 'react-admin';
import { RichTextField } from 'ra-ui-materialui';
import { makeStyles } from '@material-ui/core/styles';
import ProductTitle from './ProductTitle';
import ProductSpecsField from './ProductSpecsField/ProductSpecsField';
import choices from './choices';
import { ConfigIds } from '../../helpers/types';
import GlobalTaxView from './GlobalTaxView';

const useStyles = makeStyles({
    containerFlex: {
        display: 'flex',
        '& div': {
            padding: '0px 0px',
            '& div': {
                minWidth: '130px',
            },
        },
    },
    conditionalCustomPricingField: {
        padding: '0px 0px',
        '& div': {
            '& div': {
                minWidth: '130px',
            },
        },
    },
});
const NumberCustomPricingField = ({ record, ...rest }) => {
    const classes = useStyles();
    const all = { record, ...rest };
    return record?.pricing?.hasCustomPricing
        && (
            <SimpleShowLayout className={classes.conditionalCustomPricingField}>
                <>
                    <Labeled label="Ingresos Brutos">
                        <NumberField source={`pricing.customPricing.${ConfigIds.PRICING_IIBB}`} {...all} />
                    </Labeled>
                    <Labeled label="Comisión bancaria">
                        <NumberField source={`pricing.customPricing.${ConfigIds.PRICING_COM_BANCARIA}`} {...all} />
                    </Labeled>
                    <Labeled label="Gastos Administrativos">
                        <NumberField source={`pricing.customPricing.${ConfigIds.PRICING_G_ADM}`} {...all} />
                    </Labeled>
                    <Labeled label="Markup">
                        <NumberField source={`pricing.customPricing.${ConfigIds.PRICING_MARKUP}`} {...all} />
                    </Labeled>
                    <Labeled label="Comisión Mercado Pago">
                        <NumberField source={`pricing.customPricing.${ConfigIds.PRICING_CMP}`} {...all} />
                    </Labeled>
                </>
                <>
                    <Labeled label="Interés de la Cuota">
                        <NumberField source={`pricing.customPricing.${ConfigIds.INTEREST_RATE}`} {...all} label="Interés de la Cuota" />
                    </Labeled>
                    <Labeled label="Comisión bancaria (precio de lista)">
                        <NumberField source={`pricing.customPricing.${ConfigIds.PRICING_COM_BANCARIA_PRECIO_LISTA}`} {...all} />
                    </Labeled>
                </>
            </SimpleShowLayout>
        );
};

export default (props) => {
    const classes = useStyles();
    return (
        <Show title={<ProductTitle />} {...props}>
            <TabbedShowLayout>
                <Tab label="Main">
                    <TextField
                        label="Nombre del Producto"
                        source="name"
                    />
                    <TextField
                        label="Slug"
                        source="slug"
                    />
                    <SimpleShowLayout className={classes.containerFlex}>
                        <TextField
                            label="Code"
                            source="code"
                        />
                        <TextField
                            label="Gtin"
                            source="gtin"
                        />
                    </SimpleShowLayout>
                    <TextField
                        label="Garantía"
                        source="warranty"
                    />
                    <TextField
                        label="Marca"
                        source="brand"
                    />
                    <RichTextField
                        label="Descripción"
                        source="description"
                    />
                    <NumberField source="weight" />
                    <TextField
                        label="Cantidad de ventas"
                        source="salesQuantity"
                    />
                    <BooleanField source="published" />
                    <BooleanField source="hideOnProbuilder" label="Ocultar en ProBuilder" />
                    <BooleanField source="hideOnEcommerce" label="Ocultar en Ecommerce" />
                </Tab>
                <Tab label="Images">
                    <ArrayField
                        label="Imágenes"
                        source="images"
                    >
                        <SingleFieldList>
                            <ImageField source="url" title="title" />
                        </SingleFieldList>
                    </ArrayField>
                </Tab>
                <Tab label="Pricing & Stock">
                    <NumberField label="Costo USD" source="pricing.cost" />
                    <SelectField label="IVA" source="pricing.iva" choices={choices.iva} />
                    <SimpleShowLayout className={classes.containerFlex}>
                        <NumberField label="Precio USD" source="price.special.USD" />
                        <NumberField label="Precio ARS" source="price.special.ARS" />
                        <NumberField label="Precio tachado ARS" source="price.special.strikethrough" />
                    </SimpleShowLayout>
                    <SimpleShowLayout className={classes.containerFlex}>
                        <NumberField label="Precio USD (lista)" source="price.list.USD" />
                        <NumberField label="Precio ARS (lista)" source="price.list.ARS" />
                    </SimpleShowLayout>
                    <TextField label="Etiqueta" source="customLabel" />
                    <SimpleShowLayout className={classes.containerFlex}>
                        <NumberField label="Stock" source="stock" />
                        <TextField label="Proveedor" source="provider" />
                    </SimpleShowLayout>
                </Tab>
                <Tab label="Custom Pricing">
                    <BooleanField label="Custom Pricing (Estos valores pisan los parámetros globales actuales)" source="pricing.hasCustomPricing" />
                    <NumberCustomPricingField />
                    <GlobalTaxView />
                </Tab>
                <Tab label="Specifications">
                    <ProductSpecsField />
                </Tab>
                <Tab label="SEO">
                    <TextField source="seo.description" label="Meta description" />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
