import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectField,
    Labeled,
} from 'react-admin';
import choices from '../choices';
import classes from './styles';

const ChairProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Material">
                <SelectField
                    source="specs.material"
                    choices={choices.chair.material}
                    record={record}
                />
            </Labeled>
            <Labeled label="Peso Máximo Soportado">
                <SelectField
                    source="specs.maximumSupportedWeight"
                    choices={choices.chair.maximumSupportedWeight}
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default ChairProductSpecsField;
