import React from 'react';
import { ArrayInput, SimpleFormIterator, TextInput } from 'react-admin';

const Brands = () => (
    <ArrayInput source="value" label="Marcas">
        <SimpleFormIterator>
            <TextInput source="id" label="ID" />
        </SimpleFormIterator>
    </ArrayInput>
);

export default Brands;
