import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {
    CardHeader, CircularProgress, Divider, Typography,
} from '@material-ui/core';
import * as React from 'react';

const getHeader = (title, mainContent) => (
    <>
        <Typography variant="h5" align="center">
            {title}
        </Typography>
        <Typography variant="h4" align="center" style={{ marginTop: 8 }}>
            {mainContent}
        </Typography>
    </>
);

const DashboardCard = ({
    title, mainContent, additionalContent, footer, loading, backgroundColor = '#FFFFFF', mainColor = '#000000',
}) => (
    <Card style={{
        backgroundColor, borderRadius: 12, textAlign: 'center', height: '100%',
    }}
    >
        <CardHeader
            title={getHeader(title, mainContent)}
            style={{ color: mainColor, paddingBottom: 10 }}
        />
        <Divider variant="middle" />
        <CardContent style={{ color: '#404040', paddingTop: 10 }}>
            {loading ? <CircularProgress /> : (
                <>
                    <Typography variant="body1" align="center">
                        {additionalContent || ''}
                    </Typography>
                    <Typography variant="body2" align="center">
                        {footer || ''}
                    </Typography>
                </>
            )}
        </CardContent>
    </Card>
);

export default DashboardCard;
