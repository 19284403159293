import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    NumberField,
    SelectField,
    BooleanField,
    Labeled,
} from 'react-admin';
import choices from '../choices';
import classes from './styles';

const StorageProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Tipo">
                <SelectField
                    source="specs.type"
                    choices={choices.storage.types}
                    record={record}
                />
            </Labeled>
            <Labeled label="Watts consumed">
                <NumberField
                    source="specs.watts"
                    record={record}
                />
            </Labeled>
            <Labeled label="Tecnología">
                <SelectField
                    source="specs.technology"
                    choices={choices.storage.technologies}
                    record={record}
                />
            </Labeled>
            <Labeled label="Capacidad (GB)">
                <NumberField
                    source="specs.capacity"
                    record={record}
                />
            </Labeled>
        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Conexión">
                <SelectField
                    source="specs.connection"
                    choices={choices.storage.connections}
                    record={record}
                />
            </Labeled>
            <Labeled label="Protocolo">
                <SelectField
                    source="specs.protocol"
                    choices={choices.storage.protocols}
                    record={record}
                />
            </Labeled>
            <Labeled label="Compatibilidad Notebook">
                <BooleanField
                    source="specs.notebookCompatible"
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default StorageProductSpecsField;
