import * as React from 'react';
import {
    Edit, FormTab, SelectInput, TabbedForm,
    TextInput, TopToolbar, ShowButton, ImageInput, ImageField,
} from 'react-admin';
import { required } from 'ra-core';
import UserTitle from './UserTitle';
import choices from './choices';
import ChangePasswordButton from './ChangePasswordButton';

const UserActions = ({ basePath, data }) => (
    <TopToolbar>
        <ShowButton basePath={basePath} record={data} />
        <ChangePasswordButton record={data} />
    </TopToolbar>
);

export default (props) => (
    <Edit title={<UserTitle />} actions={<UserActions />} {...props}>
        <TabbedForm>
            <FormTab label="Main">
                <TextInput source="email" validate={[required()]} />
                <TextInput source="first_name" />
                <TextInput source="last_name" />
                <SelectInput source="role" choices={choices.roles} validate={[required()]} />
                <ImageInput source="image">
                    <ImageField source="url" title="title" />
                </ImageInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);
