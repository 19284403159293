import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import {
    NumberField,
    SelectField,
    BooleanField,
    ArrayField,
    Labeled,
} from 'react-admin';
import choices from '../choices';
import classes from './styles';

const CustomSocketListField = ({ data, choices: socketChoices }) => Object.values(data).map(
    (idSocket) => {
        const socket = socketChoices.find((item) => item.id === idSocket);
        return <Typography variant="body2">{socket.name}</Typography>;
    },
);

const CpuCoolerProductSpecsField = ({ record }) => (
    <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Tipo">
                <SelectField
                    source="specs.cooler_type"
                    choices={choices.cpu_cooler.type}
                    record={record}
                />
            </Labeled>
            <Labeled label="Tamaño radiador">
                <SelectField
                    source="specs.case_radiator_width"
                    choices={choices.cpu_cooler.case_radiator_width}
                    record={record}
                />
            </Labeled>
            <Labeled label="Tamaño ventilador">
                <SelectField
                    source="specs.fanSize"
                    choices={choices.cpu_cooler.fan_sizes}
                    record={record}
                />
            </Labeled>

        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Socket">
                <ArrayField
                    choices={choices.cpu.socket}
                    record={record}
                    source="specs.sockets"
                >
                    <CustomSocketListField />
                </ArrayField>
            </Labeled>

        </Box>
        <Box display="flex" flexDirection="row" style={classes.boxStyles}>
            <Labeled label="Watts consumed">
                <NumberField
                    source="specs.watts"
                    record={record}
                />
            </Labeled>
            <Labeled label="Iluminación">
                <SelectField
                    source="specs.lighting"
                    choices={choices.cpu_cooler.lighting_options}
                    record={record}
                />
            </Labeled>
            <Labeled label="Pantalla">
                <BooleanField
                    source="specs.screen"
                    record={record}
                />
            </Labeled>
        </Box>
    </Box>
);

export default CpuCoolerProductSpecsField;
