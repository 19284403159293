import * as React from 'react';
import {
    Edit, FormTab, ImageField, ImageInput, TabbedForm, TextInput,
} from 'react-admin';
import { required } from 'ra-core';
import GameTitle from './GameTitle';

// TODO Falta hacer que funcione bien con 1 imagen oslamente a nivel admin y core
export default (props) => (
    <Edit title={<GameTitle />} {...props}>
        <TabbedForm>
            <FormTab label="Main">
                <TextInput source="name" validate={[required()]} />
                <ImageInput source="image">
                    <ImageField source="url" title="title" />
                </ImageInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);
