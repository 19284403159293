import React from 'react';
import { TableHead, TableRow, TableCell } from '@material-ui/core';

const TableHeadForColumns = ({ columns }) => (
    <TableHead>
        <TableRow>
            {columns.map((columnName) => <TableCell size="small" key={columnName} style={{ fontWeight: 'bold' }}>{columnName}</TableCell>)}
        </TableRow>
    </TableHead>
);

export default TableHeadForColumns;
