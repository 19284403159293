import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { JsonField } from 'react-admin-json-view';

const MPAndVCTab = ({ record, ...rest }) => {
    const all = { record, ...rest };
    return (
        <Grid container>
            <Grid item md={6}>
                <Typography variant="subtitle">Mercado Pago</Typography>
                <Box mt={1} gap={1}>
                    <JsonField source="paymentData.mpRequest" label="Request a MP al pagar" {...all} />
                    <JsonField source="paymentData.mpResponse" label="Respuesta de MP al pagar" {...all} />
                </Box>
            </Grid>
            <Grid item md={6}>
                <Typography variant="subtitle">Via Cargo</Typography>
                <Box mt={1} gap={1}>
                    <JsonField source="vcRequest" label="Request a VC al solicitar env&iacute;o" {...all} />
                    <JsonField source="vcResponse" label="Respuesta de VC al solicitar env&iacute;o" {...all} />
                </Box>
            </Grid>
        </Grid>
    );
};

export default MPAndVCTab;
