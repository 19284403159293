const jsonwebtoken = require('jsonwebtoken');

const adminRoles = ['admin', 'logistica', 'administrativo'];

const authProvider = {
    login: ({ username, password }) => {
        const request = new Request(`${process.env.REACT_APP_API_URL}/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
            credentials: 'include',
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                const decodedToken = jsonwebtoken.decode(token);
                localStorage.setItem('role', decodedToken.role);
            });
    },
    logout: () => {
        localStorage.removeItem('role');
        const request = new Request(`${process.env.REACT_APP_API_URL}/auth/logout`, {
            method: 'POST',
        });
        return fetch(request, { credentials: 'include' }).then((response) => {
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject();
            }
            return Promise.resolve();
        });
    },

    checkAuth: () => {
        if (!localStorage.getItem('role')) {
            localStorage.setItem('requestingPermissions', true);
            const request = new Request(`${process.env.REACT_APP_API_URL}/auth/me`);
            return fetch(request, { credentials: 'include' })
                .then((response) => {
                    if (response.status < 200 || response.status >= 300) {
                        return Promise.reject();
                    }
                    return response.json();
                })
                .then(({ role }) => {
                    localStorage.setItem('role', role);
                    if (adminRoles.includes(role)) return Promise.resolve();
                    return Promise.reject();
                })
                .finally(() => {
                    localStorage.setItem('requestingPermissions', false);
                });
        }
        return adminRoles.includes(localStorage.getItem('role')) ? Promise.resolve() : Promise.reject();
    },

    checkError: (error) => {
        const { status } = error;
        if (status === 401 || status === 403) {
            return Promise.reject();
        }
        return Promise.resolve();
    },

    getPermissions: () => {
        const role = localStorage.getItem('role');
        return adminRoles.includes(role) ? Promise.resolve(role) : Promise.reject();
    },
};

export default authProvider;
