import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify } from 'react-admin';
import {
    Switch, FormControlLabel, FormGroup, Button, CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    button: {
        maxWidth: '160px',
        background: '#3f51b5',
        marginTop: '16px',
        color: '#fff',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            color: '#3f51b5',
        },
    },
}));

const TestViaCargo = ({ permissions }) => {
    const [loading, setLoading] = useState(false);
    const [smokeTestMode, setSmokeTestMode] = useState(false);
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const handleViaCargoTest = () => {
        setLoading(true);
        dataProvider.viaCargoTest('shipping', {
            mode: smokeTestMode,
        })
            .then(() => {
                notify(`Test ${smokeTestMode ? 'individual aleatorio' : 'masivo'} en progreso. El resultado será comunicado via email.`);
            })
            .catch((error) => {
                notify(error.body ? error.body.error : 'Error al ejecutar test');
            })
            .finally(() => setLoading(false));
    };

    return (
        <FormGroup>
            <FormControlLabel
                control={(
                    <Switch
                        color="primary"
                        checked={smokeTestMode}
                        onClick={() => setSmokeTestMode(!smokeTestMode)}
                        disabled={loading || permissions !== 'admin'}
                    />
                )}
                label="Smoke Test Mode"
            />
            <Button
                onClick={handleViaCargoTest}
                disabled={loading || permissions !== 'admin'}
                className={classes.button}
            >
                {loading ? (<CircularProgress size="1.5rem" style={{ color: '#FFFFFF' }} />) : 'Ejecutar Test'}
            </Button>
        </FormGroup>
    );
};

export default TestViaCargo;
