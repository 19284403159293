import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    Labeled,
    NumberField,
    SelectField,
    TextField,
    BooleanField,
    RichTextField,
    ReferenceArrayField,
    Datagrid,
    ShowButton,
    ReferenceField,
    ArrayField,
} from 'react-admin';
import choices from '../choices';
import MonitorProductSpecsField from './MonitorProductSpecsField';
import MouseProductSpecsField from './MouseProductSpecsField';
import KeyboardProductSpecsField from './KeyboardProductSpecsField';
import RAMProductSpecsField from './RAMProductSpecsField';
import CpuProductSpecsField from './CpuProductSpecsField';
import HeadsetProductSpecsField from './HeadsetProductSpecsField';
import ChairProductSpecsField from './ChairProductSpecsField';
import GpuProductSpecsField from './GpuProductSpecsField';
import CaseFanProductSpecsField from './CaseFanProductSpecsField';
import CpuCoolerProductSpecsField from './CpuCoolerProductSpecsField';
import MotherProductSpecsField from './MotherProductSpecsField';
import StorageProductSpecsField from './StorageProductSpecsField';
import PowerProductSpecsField from './PowerProductSpecsField';
import CaseProductSpecsField from './CaseProductSpecsField';

export default ({ record, ...rest }) => {
    const specsForTypes = {
        case: <CaseProductSpecsField record={record} />,
        cpu_cooler: <CpuCoolerProductSpecsField record={record} />,
        power: <PowerProductSpecsField record={record} />,
        gpu: <GpuProductSpecsField record={record} />,
        storage: <StorageProductSpecsField record={record} />,
        cpu: <CpuProductSpecsField record={record} />,
        ram: <RAMProductSpecsField record={record} />,
        mother: <MotherProductSpecsField record={record} />,
        mouse: <MouseProductSpecsField record={record} />,
        monitor: <MonitorProductSpecsField record={record} />,
        build: (
            <Box display="flex" flexDirection="column">
                <Labeled label="Customizable">
                    <BooleanField
                        source="specs.customizable"
                        record={record}
                    />
                </Labeled>
                <Labeled label="CPU Brand">
                    <SelectField
                        source="specs.cpu_brand"
                        choices={choices.cpu_brands}
                        record={record}
                    />
                </Labeled>
                <Labeled label="Sistema Operativo">
                    <RichTextField
                        source="specs.operativeSystem"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Monitor">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.monitor.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción monitor">
                    <RichTextField
                        source="specs.monitor.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Mouse">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.mouse.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción mouse">
                    <RichTextField
                        source="specs.mouse.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Teclado">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.keyboard.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción teclado">
                    <RichTextField
                        source="specs.keyboard.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Conectividad">
                    <RichTextField
                        source="specs.connectivity"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Puertos">
                    <RichTextField
                        source="specs.ports"
                        record={record}
                    />
                </Labeled>

                <Labeled label="Mother">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.mother.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción mother">
                    <RichTextField
                        source="specs.mother.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="CPU">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.cpu.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción CPU">
                    <RichTextField
                        source="specs.cpu.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="RAM">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.ram.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción RAM">
                    <RichTextField
                        source="specs.ram.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="GPU">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.gpu.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción GPU">
                    <RichTextField
                        source="specs.gpu.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Storage">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.storage.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción Storage">
                    <RichTextField
                        source="specs.storage.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Case">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.case.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción case">
                    <RichTextField
                        source="specs.case.description"
                        record={record}
                    />
                </Labeled>
                <Labeled label="Power">
                    <ReferenceArrayField
                        reference="products"
                        source="specs.power.products"
                        record={record}
                        {...rest}
                    >
                        <Datagrid style={{ tableLayout: 'fixed' }}>
                            <TextField source="name" />
                            <TextField source="code" />
                            <ShowButton />
                        </Datagrid>
                    </ReferenceArrayField>
                </Labeled>
                <Labeled label="Descripción power">
                    <RichTextField
                        source="specs.power.description"
                        record={record}
                    />
                </Labeled>

                <ArrayField
                    source="specs.games_compatibility"
                    label="Juegos Compatibles"
                    record={record}
                    {...rest}
                >
                    <Datagrid style={{ tableLayout: 'fixed' }}>
                        <ReferenceField
                            reference="games"
                            source="game"
                            label="Game"
                        >
                            <TextField source="name" />
                        </ReferenceField>
                        <BooleanField label="Compatible" source="isCompatible" />
                    </Datagrid>
                </ArrayField>
            </Box>

        ),
        case_fan: (<CaseFanProductSpecsField record={record} />),
        keyboard: (
            <KeyboardProductSpecsField record={record} />
        ),
        headset: (
            <HeadsetProductSpecsField record={record} />
        ),
        chair: (
            <ChairProductSpecsField record={record} />
        ),
    };
    return specsForTypes[record.type] ?? <></>;
};
