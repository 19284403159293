import * as React from 'react';
import { useFormState } from 'react-final-form';
import {
    SelectInput,
    TextInput,
    NumberInput,
    BooleanInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
} from 'react-admin';
import { required } from 'ra-core';
import RichTextInput from 'ra-input-rich-text';
import choices from '../choices';
import GameCompatibilityInput from '../Builds/GameCompatibilityInput';
import MonitorProductSpecsInput from './MonitorProductSpecsInput';
import MouseProductSpecsInput from './MouseProductSpecsInput';
import KeyboardProductSpecsInput from './KeyboardProductSpecsInput';
import HeadsetProductSpecsInput from './HeadsetProductSpecsInput';
import ChairProductSpecsInput from './ChairProductSpecsInput';
import RAMProductSpecsInput from './RAMProductSpecsInput';
import CpuProductSpecsInput from './CpuProductSpecsInput';
import GpuProductSpecsInput from './GpuProductSpecsInput';
import CaseFanProductSpecsInput from './CaseFanProductSpecsInput';
import CpuCoolerProductSpecsInput from './CpuCoolerProductSpecsInput';
import MotherProductSpecsInput from './MotherProductSpecsInput';
import PowerProductSpecsInput from './PowerProductSpecsInput';
import StorageProductSpecsInput from './StorageProductSpecsInput';
import CaseProductSpecsInput from './CaseProductSpecsInput';

export default (props) => {
    const { values } = useFormState();

    const specsForTypes = {
        case: <CaseProductSpecsInput {...props} />,
        power: <PowerProductSpecsInput {...props} />,
        gpu: <GpuProductSpecsInput {...props} />,
        storage: <StorageProductSpecsInput record={values} />,
        cpu_cooler: <CpuCoolerProductSpecsInput record={values} />,
        cpu: <CpuProductSpecsInput />,
        ram: <RAMProductSpecsInput />,
        mother: <MotherProductSpecsInput record={values} />,
        mouse: <MouseProductSpecsInput />,
        monitor: <MonitorProductSpecsInput />,
        build: (
            <>
                <BooleanInput
                    label="Customizable"
                    source="specs.customizable"
                    defaultValue
                />
                <SelectInput
                    label="CPU Brand"
                    source="specs.cpu_brand"
                    choices={choices.cpu_brands}
                    validate={[required()]}
                />
                <RichTextInput
                    source="specs.operativeSystem"
                    label="Sistema Operativo"
                />
                <ReferenceArrayInput label="Monitor" reference="products" allowDuplicates source="specs.monitor.products" filter={{ type: 'monitor' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.monitor.description"
                    label="Descripción monitor. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="Mouse" reference="products" allowDuplicates source="specs.mouse.products" filter={{ type: 'mouse' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.mouse.description"
                    label="Descripción mouse. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <RichTextInput
                    source="specs.connectivity"
                    label="Conectividad"
                />
                <RichTextInput
                    source="specs.ports"
                    label="Puertos"
                />
                <ReferenceArrayInput label="Teclado" reference="products" allowDuplicates source="specs.keyboard.products" filter={{ type: 'keyboard' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.keyboard.description"
                    label="Descripción teclado. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="Mother" allowDuplicates reference="products" source="specs.mother.products" filter={{ type: 'mother' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.mother.description"
                    label="Descripción mother. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="CPU" allowDuplicates reference="products" source="specs.cpu.products" filter={{ type: 'cpu' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.cpu.description"
                    label="Descripción CPU. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="RAM" allowDuplicates reference="products" source="specs.ram.products" filter={{ type: 'ram' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.ram.description"
                    label="Descripción RAM. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="GPU" allowDuplicates reference="products" source="specs.gpu.products" filter={{ type: 'gpu' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.gpu.description"
                    label="Descripción GPU. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="Storage" allowDuplicates reference="products" source="specs.storage.products" filter={{ type: 'storage' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.storage.description"
                    label="Descripción Storage. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="Case" allowDuplicates reference="products" source="specs.case.products" filter={{ type: 'case' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.case.description"
                    label="Descripción case. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"
                />
                <ReferenceArrayInput label="Power" allowDuplicates reference="products" source="specs.power.products" filter={{ type: 'power' }}>
                    <AutocompleteArrayInput optionText="niceAdminName" />
                </ReferenceArrayInput>
                <RichTextInput
                    source="specs.power.description"
                    label="Descripción power. Tener en cuenta que al agregar una descripción, es lo que se mostrará en la web como el nombre del componente"

                />
                <GameCompatibilityInput />
            </>
        ),
        case_fan: <CaseFanProductSpecsInput />,
        keyboard: (
            <KeyboardProductSpecsInput />
        ),
        headset: (
            <HeadsetProductSpecsInput />
        ),
        chair: (
            <ChairProductSpecsInput />
        ),
    };
    return specsForTypes[values.type] ?? <br />;
};
