import * as React from 'react';
import {
    Create,
    FormTab,
    TabbedForm,
    TextInput,
    NumberInput,
    DateTimeInput,
} from 'react-admin';
import { required } from 'ra-core';

export default (props) => (
    <Create title="Create a Coupon" {...props}>
        <TabbedForm>
            <FormTab label="Main">
                <TextInput source="code" label="Código" validate={[required()]} />
                <NumberInput source="discount_percentage" label="Porcentaje de descuento" min={0} max={100} validate={[required()]} />
                <DateTimeInput source="date_from" label="Desde" showTime validate={[required()]} />
                <DateTimeInput source="date_to" label="Hasta" showTime />
                <NumberInput source="max_uses" label="Límite de usos" min={0} />
            </FormTab>
        </TabbedForm>
    </Create>
);
