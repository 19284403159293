import * as React from 'react';
import {
    Datagrid, EditButton,
    List, ShowButton,
    TextField,
} from 'react-admin';

import GameFilter from './GameFilter';

export default (props) => (
    <List {...props} filters={<GameFilter />} bulkActionButtons={false}>
        <Datagrid>
            <TextField source="name" />
            <EditButton basePath="/games" />
            <ShowButton basePath="/games" />
        </Datagrid>
    </List>
);
