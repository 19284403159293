import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectInput,
    BooleanInput,
    FormDataConsumer,
} from 'react-admin';
import { useForm } from 'react-final-form';

import choices, { mechanicalKeyboardType } from '../choices';
import classes from './styles';

const KeyboardProductSpecsInput = () => {
    const form = useForm();

    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" style={classes.boxStyles}>
                <SelectInput
                    choices={choices.keyboardTypes}
                    label="Tipo de teclado"
                    source="specs.type"
                    style={classes.textInputStyles}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        const isMechanicalKeyboard = formData.specs
                        && formData.specs.type === mechanicalKeyboardType;
                        if (!isMechanicalKeyboard) {
                            form.change('specs.switch', null);
                        }

                        return (
                            isMechanicalKeyboard ? (
                                <SelectInput
                                    choices={choices.keyboardSwitches}
                                    label="Switch"
                                    source="specs.switch"
                                    style={classes.textInputStyles}
                                    {...rest}
                                />
                            ) : null);
                    }}
                </FormDataConsumer>
                <SelectInput
                    choices={choices.keyboardBacklights}
                    label="Retroiluminación"
                    source="specs.backlight"
                    style={classes.textInputStyles}
                />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <SelectInput
                    choices={choices.keyboardConnectivity}
                    label="Conectividad"
                    source="specs.connectivity"
                    style={classes.textInputStyles}
                />
                <SelectInput
                    choices={choices.keyboardSize}
                    label="Tamaño"
                    source="specs.size"
                    style={classes.textInputStyles}
                />
                <BooleanInput
                    label="Teclas Programables"
                    source="specs.programmableKeys"
                    defaultValue
                />
            </Box>
        </Box>
    );
};

export default KeyboardProductSpecsInput;
