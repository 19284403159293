import * as React from 'react';
import {
    NumberField,
    ReferenceManyField,
    Datagrid,
    DateField,
    EditButton,
    ImageField,
    SelectField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField, TopToolbar, Pagination, ShowButton,
} from 'react-admin';
import UserTitle from './UserTitle';
import choices from './choices';
import ChangePasswordButton from './ChangePasswordButton';
import cartHelper from '../../helpers/cartHelper';

const UserActions = ({ basePath, data }) => (
    <TopToolbar>
        <EditButton basePath={basePath} record={data} />
        <ChangePasswordButton record={data} />
    </TopToolbar>
);

export default (props) => (
    <Show title={<UserTitle />} actions={<UserActions />} {...props}>
        <TabbedShowLayout>
            <Tab label="Main">
                <TextField source="email" />
                <TextField source="first_name" />
                <TextField source="last_name" />
                <SelectField source="role" choices={choices.roles} />
                <ImageField label="Image" source="image.url" title="image.title" />
            </Tab>
            <Tab label="Orders">
                <ReferenceManyField pagination={<Pagination />} perPage={10} sort={{ field: 'statusDates.new', order: 'DESC' }} label="Orders" reference="orders" target="buyer">
                    <Datagrid>
                        <TextField source="code" label="C&oacute;digo" />
                        <NumberField
                            source="totalPrice"
                            label="Monto total"
                            locales={cartHelper.formaterPesosLocales}
                            options={{
                                style: cartHelper.formaterPesosStyle,
                                currency: cartHelper.formaterPesosCurrency,
                            }}
                        />
                        <TextField source="billId" label="N&uacute;mero de factura" />
                        <DateField source="statusDates.new" label="Fecha de Compra Realizada" showTime />
                        <ShowButton basePath="/orders" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);
