import * as React from 'react';
import {
    Button, useMutation, useNotify, useRefresh,
} from 'react-admin';
import { LocalShipping } from '@material-ui/icons';

export default ({ record }) => {
    const refresh = useRefresh();
    const notify = useNotify();

    const [requestShipping, { loading }] = useMutation({
        type: 'requestShipping',
        payload: { orderId: record.id },
    }, {
        onSuccess: () => { notify('Shipping requested'); refresh(); },
        onFailure: () => { notify('Error requesting shipping', 'warning'); },
    });

    return (
        <Button label="Request shipping" onClick={requestShipping} disabled={loading} alignIcon="left">
            <LocalShipping />
        </Button>
    );
};
