import * as React from 'react';
import { Box } from '@material-ui/core';
import {
    SelectInput,
    NumberInput,
    BooleanInput,
    required,
} from 'react-admin';
import { PowerCablingOptions, PowerCertification80PlusOptions } from '../../../helpers/types';
import { isRecordNew } from './utils';

import choices from '../choices';
import classes from './styles';

const PowerProductSpecsInput = ({ record }) => {
    const recordIsNew = isRecordNew(record);
    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <NumberInput
                    label="Watts nominal"
                    source="specs.watts_supported"
                    validate={[required()]}
                    style={classes.textInputStyles}
                />
                <SelectInput
                    label="Formato"
                    source="specs.format"
                    choices={choices.power.format}
                    style={classes.textInputStyles}
                    validate={[required()]}
                />
                <SelectInput
                    label="Certificación 80 Plus"
                    source="specs.certification80Plus"
                    choices={choices.power.certification80Plus}
                    style={classes.textInputStyles}
                    defaultValue={recordIsNew ? PowerCertification80PlusOptions.BRONZE : undefined}
                />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center" style={classes.boxStyles}>
                <SelectInput
                    label="Cableado"
                    source="specs.cabling"
                    choices={choices.power.cabling}
                    style={classes.textInputStyles}
                    defaultValue={recordIsNew ? PowerCablingOptions.FIJO : undefined}
                />
                <BooleanInput
                    label="Iluminación"
                    source="specs.lighting"
                    style={classes.textInputStyles}
                    defaultValue={recordIsNew ? true : undefined}
                />
            </Box>
        </Box>
    );
};

export default PowerProductSpecsInput;
