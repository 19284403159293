import { defaultTheme } from 'react-admin';

const generateTheme = (isBlueColorActive) => ({
    ...defaultTheme,
    palette: {
        ...defaultTheme.palette,
        primary: {
            main: isBlueColorActive ? '#2196f3' : '#f50057',
        },
        secondary: {
            main: isBlueColorActive ? '#2196f3' : '#f50057',
        },
    },
});

export default generateTheme;
