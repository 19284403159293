import * as React from 'react';
import {
    Datagrid, DatagridBody, DatagridRow,
    EditButton, List, TextField,
} from 'react-admin';
import { ConfigIds } from '../../helpers/types';

const dontShow = [ConfigIds.DESTINATIONS, ConfigIds.AGENCIES];

const MyDatagridRow = ({ id, ...rest }) => {
    if (dontShow.includes(id)) return null;
    return <DatagridRow {...rest} />;
};
const MyDatagridBody = (props) => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = (props) => <Datagrid {...props} body={<MyDatagridBody />} />;

export default (props) => (
    <List {...props} bulkActionButtons={false} perPage={25}>
        <MyDatagrid>
            <TextField source="id" />
            <TextField source="value" />
            <EditButton basePath="/configs" />
        </MyDatagrid>
    </List>
);
